/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {
  charityVerifyOtp,
  charityVerifyOtpSimple,
  login,
  registerCharitySimple,
  userDetails,
} from '../core/_requests'
import {ToastContainer, toast} from 'react-toastify'
import OtpInput from 'react-otp-input'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../core/Auth'

export function CharityVerifyOtp() {
  const [loading, setLoading] = useState(false)
  const [message, setMassage] = useState('')
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const storedValue = localStorage.getItem('charityVerifiedEmailForOtp')
  const storedPswValue = localStorage.getItem('charityLogin')
  const email = storedValue ? JSON.parse(storedValue) : null
  const password = storedPswValue ? JSON.parse(storedPswValue) : null

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    setMassage('')
    try {
      if (otp.length !== 6) {
        setMassage('Please enter a 6-digit OTP.')
        setLoading(false)
        return
      }
      if (!/^\d+$/.test(otp)) {
        setMassage('Please enter numeric values only.')
        setLoading(false)
        return
      }
      let externalCharity = localStorage.getItem('externalCharity')
      if (externalCharity) {
        const verifyResult = await charityVerifyOtpSimple(Number(otp), email)
        if (verifyResult.status === 200) {
          toast.success(verifyResult.data.message)
          setLoading(false)
          externalCharity = Object.assign({}, JSON.parse(externalCharity))
          if ((externalCharity as any)?.website) {
            console.log('Registering candid charity')
            const result = await registerCharitySimple({
              ...(externalCharity as any),
              verificationToken: (verifyResult.data as any).verificationToken,
            })
            if (result.status === 201) {
              toast.success(result.data.message)
              localStorage.removeItem('externalCharity')
            } else {
              toast.error(result.data.message)
              setLoading(false)
              return
            }
          }
        } else {
          toast.error(verifyResult.data.message)
          setLoading(false)
          return
        }
      } else {
        const result = await charityVerifyOtp(Number(otp), email)
        if (result.status === 202) {
          toast.success(result.data.message)
          setLoading(false)
        } else {
          toast.error(result.data.message)
          setLoading(false)
          return
        }
      }

      try {
        const {data: auth, status} = await login(email, password)
        if (auth) {
          const user = await userDetails(auth.token, auth.role)
          const currentUser = {
            ...user.data.data,
            token: auth.token,
            role: user.data.data.role,
            status: status || user.status,
          }
          saveAuth(currentUser)
          setCurrentUser(currentUser)
          if (user.status === 200 || user.status === 222) {
            navigate('/')
            // toast.success('Fill remaining details through edit profile')
          }
        }
      } catch (error: any) {
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    } catch (error: any) {
      toast.error(error.response.data.message || 'Something went wrong!')
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_login_signin_form'>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Verify 6 Digits Code</h1>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8 d-flex flex-column align-items-center justify-content-center'>
        <OtpInput
          value={otp}
          onChange={(value) => setOtp(value)}
          numInputs={6}
          shouldAutoFocus
          renderSeparator={<span>-</span>}
          inputStyle={{
            border: '1px solid transparent',
            borderRadius: '8px',
            width: '50px',
            height: '50px',
            fontSize: '16px',
            color: '#000',
            fontWeight: '600',
            caretColor: 'blue',
            outline: 'none',
            textAlign: 'center',
          }}
          // Add the renderInput property
          renderInput={(props) => <input {...props} />}
        />
        {message && (
          <div className='fv-plugins-message-container'>
            <span role='alert' style={{color: 'red'}}>
              {message}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <ToastContainer />
      {/* end::Action */}
    </form>
  )
}

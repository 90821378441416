import {useAuth} from '../../../app/modules/auth'

export default function SingleTextButtonBody({
  body,
  handleClose,
  buttonText,
  img,
  signoutButtonText,
}) {
  const {logout} = useAuth()
  return (
    <div className='text-center'>
      <div className='card-body'>
        {img && img}
        <p className='card-text text-muted text-dark fw-bolder fs-3 mb-0'>{body?.title}</p>

        <div className='d-flex my-4 flex-center'>
          <button
            className='btn btn-primary font-weight-bold px-9 py-4 my-3'
            onClick={() => handleClose()}
          >
            {buttonText}
          </button>
          {signoutButtonText && (
            <button
              className='btn btn-danger font-weight-bold px-9 py-4 my-3 mx-3'
              onClick={logout}
            >
              {signoutButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
